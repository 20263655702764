import PlaySound from "../playSound/PlaySound";
import langos from '../../assets/pictures/langos.png';
import p1 from '../../assets/pictures/p1.jpg';
import p2 from '../../assets/pictures/p2.jpg';
import p3 from '../../assets/pictures/p3.jpg';
import p4 from '../../assets/pictures/p4.jpg';
import p5 from '../../assets/pictures/p5.jpg';
import p6 from '../../assets/pictures/p6.jpg';
import p7 from '../../assets/pictures/p7.jpg';
import p8 from '../../assets/pictures/p8.jpg';
import p9 from '../../assets/pictures/p9.jpg';
import p10 from '../../assets/pictures/p10.jpg';
import p11 from '../../assets/pictures/p11.jpg';
import p12 from '../../assets/pictures/p12.jpg';
import p13 from '../../assets/pictures/p13.jpg';
import p14 from '../../assets/pictures/p14.jpg';
import p15 from '../../assets/pictures/p15.jpg';
import p16 from '../../assets/pictures/p16.jpg';
import p17 from '../../assets/pictures/p17.jpg';
import p18 from '../../assets/pictures/p18.jpg';
import p19 from '../../assets/pictures/p19.jpg';
import p20 from '../../assets/pictures/p20.jpg';
import p21 from '../../assets/pictures/p21.jpg';
import p22 from '../../assets/pictures/p22.jpg';
import p23 from '../../assets/pictures/p23.jpg';
import p24 from '../../assets/pictures/p24.jpg';
import p25 from '../../assets/pictures/p25.jpg';
import p26 from '../../assets/pictures/p26.jpg';
import p27 from '../../assets/pictures/p27.jpg';
import p28 from '../../assets/pictures/p28.jpg';
import p29 from '../../assets/pictures/p29.jpg';
import p30 from '../../assets/pictures/p30.jpg';
import p31 from '../../assets/pictures/p31.jpg';
import p32 from '../../assets/pictures/p32.jpg';
import p33 from '../../assets/pictures/p33.jpg';
import p34 from '../../assets/pictures/p34.jpg';
import p35 from '../../assets/pictures/p35.jpg';
import p36 from '../../assets/pictures/p36.jpg';
import p37 from '../../assets/pictures/p37.jpg';
import p38 from '../../assets/pictures/p38.jpg';
import p39 from '../../assets/pictures/p39.jpg';
import p40 from '../../assets/pictures/p40.jpg';
import p41 from '../../assets/pictures/p41.jpg';
import p42 from '../../assets/pictures/p42.jpg';
import p43 from '../../assets/pictures/p43.jpg';
import p44 from '../../assets/pictures/p44.jpg';
import p45 from '../../assets/pictures/p45.jpg';
import p46 from '../../assets/pictures/p46.jpg';
import p47 from '../../assets/pictures/p47.jpg';
import p48 from '../../assets/pictures/p48.jpg';
import p49 from '../../assets/pictures/p49.jpg';
import p50 from '../../assets/pictures/p50.jpg';
import p51 from '../../assets/pictures/p51.jpg';
import p52 from '../../assets/pictures/p52.jpg';
import p53 from '../../assets/pictures/p53.jpg';
import p54 from '../../assets/pictures/p54.jpg';
import p55 from '../../assets/pictures/p55.jpg';
import p56 from '../../assets/pictures/p56.jpg';
import p57 from '../../assets/pictures/p57.jpg';
import p58 from '../../assets/pictures/p58.jpg';
import p59 from '../../assets/pictures/p59.jpg';
import p60 from '../../assets/pictures/p60.jpg';
import p61 from '../../assets/pictures/p61.jpg';
import p62 from '../../assets/pictures/p62.jpg';
import p63 from '../../assets/pictures/p63.jpg';
import p64 from '../../assets/pictures/p64.jpg';
import p65 from '../../assets/pictures/p65.jpg';
import p66 from '../../assets/pictures/p66.jpg';
import p67 from '../../assets/pictures/p67.jpg';
import p68 from '../../assets/pictures/p68.jpg';
import p69 from '../../assets/pictures/p69.jpg';
import p70 from '../../assets/pictures/p70.jpg';
import p71 from '../../assets/pictures/p71.jpg';
import p72 from '../../assets/pictures/p72.jpg';
import p73 from '../../assets/pictures/p73.jpg';
import p74 from '../../assets/pictures/p74.jpg';
import p75 from '../../assets/pictures/p75.jpg';
import p76 from '../../assets/pictures/p76.jpg';
import p77 from '../../assets/pictures/p77.jpg';
import p100 from '../../assets/pictures/p100.jpg';
import v1 from '../../assets/pictures/v1.mp4';
import v2 from '../../assets/pictures/v2.mp4';
import v3 from '../../assets/pictures/v3.mp4';
import v4 from '../../assets/pictures/v4.mp4';
import v5 from '../../assets/pictures/v5.mp4';
import v6 from '../../assets/pictures/v6.mp4';
import v7 from '../../assets/pictures/v7.mp4';
import v8 from '../../assets/pictures/v8.mp4';
import v9 from '../../assets/pictures/v9.mp4';
import v10 from '../../assets/pictures/v10.mp4';
import v11 from '../../assets/pictures/v11.mp4';
import v12 from '../../assets/pictures/v12.mp4';
import v13 from '../../assets/pictures/v13.mp4';
import v14 from '../../assets/pictures/v14.mp4';
import v15 from '../../assets/pictures/v15.mp4';
import v16 from '../../assets/pictures/v16.mp4';
import v17 from '../../assets/pictures/v17.mp4';
import v18 from '../../assets/pictures/v18.mp4';
import finale from '../../assets/pictures/finale.mp4'
import {useEffect, useState} from "react";
import './MainPage.styles.css';





function MainPage() {
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    // Ensure that the page always starts at the top when the component mounts
    window.scrollTo({ top: 0, behavior: 'auto' });

    const handleScroll = () => {
      // Check if user is at the bottom of the page
      if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 100) {
        setIsBottom(true);
      } else {
        setIsBottom(false);
      }
    };

    // Attach scroll listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollTo = () => {
    if (isBottom) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  return (
      <div className="mainPage">
        <button
            onClick={scrollTo}
            className='scroll-button'
        >
          {isBottom ? (
              <>
                Back to <img src={langos} alt="Langos" style={{ width: '20px', height: '20px', verticalAlign: 'middle' }} /> Smiles
              </>
          ) : 'Cute Click'}
        </button>
        <PlaySound/>
        <div className='imagesContainer'>
          <img className='asset' src={p100} alt=""/>
          <img className='asset' src={p12} alt=""/>
          <img className='asset' src={p69} alt=""/>
          <img className='asset' src={p2} alt=""/>
          <img className='asset' src={p51} alt=""/>
          <img className='asset' src={p6} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v15} type="video/mp4"/>
          </video>
          {/*Above ok*/}
          <img className='asset' src={p4} alt=""/>
          <img className='asset' src={p5} alt=""/>
          <img className='asset' src={p7} alt=""/>
          <img className='asset' src={p8} alt=""/>
          <img className='asset' src={p9} alt=""/>
          <img className='asset' src={p10} alt=""/>
          <img className='asset' src={p11} alt=""/>
          <img className='asset' src={p13} alt=""/>
          <img className='asset' src={p14} alt=""/>
          <img className='asset' src={p15} alt=""/>
          <img className='asset' src={p18} alt=""/>
          <img className='asset' src={p17} alt=""/>
          <img className='asset' src={p1} alt=""/>
          {/*<img className='asset' src={p19} alt=""/>*/}
          <img className='asset' src={p20} alt=""/>
          <img className='asset' src={p21} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v11} type="video/mp4"/>
          </video>
          <img className='asset' src={p22} alt=""/>
          <img className='asset' src={p23} alt=""/>
          <img className='asset' src={p24} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v9} type="video/mp4"/>
          </video>
          <img className='asset' src={p25} alt=""/>
          <img className='asset' src={p26} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v1} type="video/mp4"/>
          </video>
          <img className='asset' src={p27} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v7} type="video/mp4"/>
          </video>
          <img className='asset' src={p28} alt=""/>
          <img className='asset' src={p29} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v6} type="video/mp4"/>
          </video>
          <img className='asset' src={p30} alt=""/>
          <img className='asset' src={p31} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v4} type="video/mp4"/>
          </video>
          <img className='asset' src={p32} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v13} type="video/mp4"/>
          </video>
          <img className='asset' src={p33} alt=""/>
          <img className='asset' src={p34} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v14} type="video/mp4"/>
          </video>
          <img className='asset' src={p35} alt=""/>
          <img className='asset' src={p36} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v16} type="video/mp4"/>
          </video>
          <img className='asset' src={p37} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v17} type="video/mp4"/>
          </video>
          <img className='asset' src={p38} alt=""/>
          <img className='asset' src={p39} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v8} type="video/mp4"/>
          </video>
          <img className='asset' src={p40} alt=""/>
          <img className='asset' src={p41} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v10} type="video/mp4"/>
          </video>
          <img className='asset' src={p42} alt=""/>
          <img className='asset' src={p76} alt=""/>
          <img className='asset' src={p43} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v2} type="video/mp4"/>
          </video>
          <img className='asset' src={p44} alt=""/>
          <img className='asset' src={p45} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v3} type="video/mp4"/>
          </video>
          {/*<img className='asset' src={p46} alt=""/>*/}
          <img className='asset' src={p47} alt=""/>
          <img className='asset' src={p77} alt=""/>
          <img className='asset' src={p48} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v5} type="video/mp4"/>
          </video>
          <img className='asset' src={p49} alt=""/>
          <img className='asset' src={p50} alt=""/>

          <img className='asset' src={p52} alt=""/>
          <img className='asset' src={p53} alt=""/>
          <img className='asset' src={p54} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v12} type="video/mp4"/>
          </video>
          <img className='asset' src={p55} alt=""/>
          <img className='asset' src={p56} alt=""/>
          <img className='asset' src={p3} alt=""/>
          <img className='asset' src={p58} alt=""/>
          <video className="asset" autoPlay muted loop playsInline preload="auto">
            <source src={v18} type="video/mp4"/>
          </video>
          <img className='asset' src={p59} alt=""/>
          <img className='asset' src={p71} alt=""/>
          <img className='asset' src={p60} alt=""/>
          <img className='asset' src={p61} alt=""/>
          <img className='asset' src={p72} alt=""/>
          <img className='asset' src={p62} alt=""/>
          <img className='asset' src={p75} alt=""/>
          <img className='asset' src={p63} alt=""/>
          <img className='asset' src={p73} alt=""/>
          <img className='asset' src={p64} alt=""/>
          <img className='asset' src={p65} alt=""/>
          <img className='asset' src={p74} alt=""/>
          <img className='asset' src={p66} alt=""/>
          <img className='asset' src={p67} alt=""/>
          <img className='asset' src={p68} alt=""/>
          <img className='asset' src={p16} alt=""/>

          <img className='asset' src={p70} alt=""/>
          <img className='asset' src={p57} alt=""/>


        </div>
        <div className='finale'>
          <video className="finalVideo" autoPlay muted loop playsInline preload="auto">
            <source src={finale} type="video/mp4"/>
        </video>
        </div>

        <p className='finalMessage'>As long as the internet exists, this memories will too!</p>
      </div>

  );
}

export default MainPage;
