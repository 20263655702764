import React, { Component } from 'react'
import './App.css';
import MainPage from "./components/mainPage/MainPage.component";
import {Route, Routes} from "react-router-dom";
import HomePage from "./components/homePage/HomePage.component";




class App extends Component {
  render() {
    return (
    <Routes>
      <Route exact path='/' element={<HomePage/>}/>
      <Route exact path='/17-03' element={<MainPage/>}/>
    </Routes>
    )
  }
}

export default App;
