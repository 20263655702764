import '../mainPage/MainPage.styles.css';
import { useEffect, useState } from "react";
import './PlaySound.styles.css';

function PlaySound() {
    const mySound = require('../../assets/sounds/Those-Eyes.mp3');

    const [play, setPlay] = useState(false);
    const [audio, setAudio] = useState(null);
    const [isVisible, setIsVisible] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const audioElement = document.getElementById("audio_tag");
        setAudio(audioElement);

        // Event listener for audio ready to play
        const handleCanPlayThrough = () => {
            setIsLoading(false); // Stop spinner when the audio is ready
        };

        // Event listener for when the audio ends
        const handleAudioEnded = () => {
            setPlay(false); // Reset play state when the audio ends
        };

        if (audioElement) {
            audioElement.addEventListener('canplaythrough', handleCanPlayThrough);
            audioElement.addEventListener('ended', handleAudioEnded);
        }

        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;

            // Calculate the percentage scrolled
            const scrollPercentage = (scrollTop + windowHeight) / documentHeight;

            // Hide the component if the user is within the last 2% of the page
            if (scrollPercentage >= 0.99) {
                setIsVisible(false);
            } else {
                setIsVisible(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (audioElement) {
                audioElement.removeEventListener('canplaythrough', handleCanPlayThrough);
                audioElement.removeEventListener('ended', handleAudioEnded);
            }
        };
    }, []);

    const handleButtonClick = () => {
        if (!play) {
            setIsLoading(true); // Start showing spinner until audio is loaded
            audio.load(); // Ensure the audio starts loading if not already
            audio.play().then(() => {
                setPlay(true);
                setIsLoading(false); // Stop spinner if it starts playing immediately
            }).catch(() => setIsLoading(false)); // Handle any play errors
        } else {
            audio.pause();
            setPlay(false);
        }
    };

    return (
        <>
            {
                isVisible && !!audio &&
                <button
                    onClick={handleButtonClick}
                    className="button-55"
                    disabled={isLoading} // Disable button while loading
                >
                    {isLoading ? (
                        <div className="spinner"></div>
                    ) : (
                        !play ? "Play" : "Stop"
                    )}
                </button>
            }
            <audio id="audio_tag" src={mySound} />
        </>
    );
}

export default PlaySound;
